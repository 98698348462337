import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LinkTabs } from '@/modules/shared/components/Organism/Tabs/LinkTabs';
import { GLOBAL_ROUTES } from '@/routes';

export function InventoryToggle({ slug }: { slug: string }) {
  const pathname = usePathname();
  const { t } = useTranslation(['inventory']);
  const options = useMemo(() => {
    return [
      {
        pathname: GLOBAL_ROUTES.COMMUNITY_INVENTORY_SLOTS(slug),
        slug: 'inventory-slots',
        label: t('inventory:avatar'),
        active: pathname === GLOBAL_ROUTES.COMMUNITY_INVENTORY_SLOTS(slug),
      },
      {
        pathname: GLOBAL_ROUTES.COMMUNITY_INVENTORY_ALL_ITEMS(slug),
        slug: 'all-items',
        label: t('inventory:allItems'),
        active: pathname === GLOBAL_ROUTES.COMMUNITY_INVENTORY_ALL_ITEMS(slug),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return <LinkTabs data={options} />;
}
