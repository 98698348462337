import { GlobalState } from '@/reducer';

export function selectAvatarUpdatedAt(communityKey?: string) {
  return (state: GlobalState) => {
    if (!communityKey) {
      return false;
    }

    const avatar = state.inventory.avatar[communityKey];
    return avatar?.updatedAt;
  };
}

export function selectAvatarChangedAt(communityKey?: string) {
  return (state: GlobalState) => {
    if (!communityKey) {
      return false;
    }

    return state.inventory.avatar[communityKey]?.changedAt;
  };
}

export function selectIsAvatarChanging(communityKey?: string) {
  return (state: GlobalState) => {
    if (!communityKey) {
      return false;
    }

    const avatar = state.inventory.avatar[communityKey];
    return (
      avatar && avatar?.changedAt && avatar?.changedAt === avatar?.updatedAt
    );
  };
}

export function selectIsAvatarLoading(communityKey?: string) {
  return (state: GlobalState) => {
    if (!communityKey) {
      return false;
    }

    const avatar = state.inventory.avatar[communityKey];
    return avatar && avatar?.isLoading;
  };
}
