import { useQuery } from '@tanstack/react-query';
import {
  ApiBase,
  getInventoryAvatar,
  getQuestHistory,
  humanize,
} from '@xborglabs/ui-shared';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { InventoryActions } from '@/redux/inventory/actions';
import { selectIsAvatarChanging } from '@/redux/inventory/selector';
import { useAppDispatch } from '@/store';

export function useAppAvatar({
  communityId,
  slug,
  enabled = false,
}: {
  communityId: string;
  slug: string;
  enabled?: boolean;
}) {
  const api = ApiBase.getInstance().getApi();
  const dispatch = useAppDispatch();
  const isAvatarChanging = useSelector(selectIsAvatarChanging(slug));
  const refetchConfig = isAvatarChanging
    ? {
        refetchInterval: 2 * 1000,
        staleTime: 2 * 1000,
        cacheTime: 1 * 1000,
      }
    : {
        refetchInterval: 30 * 1000,
        staleTime: 30 * 1000,
        cacheTime: 30 * 1000,
      };

  const result = useQuery({
    queryKey: ['AVATAR', { slug }],
    ...refetchConfig,
    queryFn: async () => {
      const result = await getInventoryAvatar(api, communityId);

      return result.avatar;
    },
    enabled: !!communityId && enabled,
  });

  // Make sure avatar is updated on the store to check for changes
  useEffect(() => {
    if (!result.data || !result.data.updatedAt) return;
    dispatch(
      InventoryActions.updateAvatar({
        updatedAt: result.data.updatedAt,
        communityKey: slug,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data, slug]);

  return result;
}

// TODO: This should be removed once backend sends total completed quests per community
export function useCommunityCompletedQuests({
  communityKey,
}: {
  communityKey?: string;
}) {
  const api = ApiBase.getInstance().getApi();
  return useQuery({
    queryKey: ['COMPLETED_QUESTS', { id: communityKey }],
    queryFn: async () => {
      const result = await getQuestHistory(api, {
        containerIds: [communityKey!],
      });

      return humanize(result.length);
    },
    enabled: !!communityKey,
  });
}
