import { SingleCommunityType } from '@xborglabs/ui-shared';
import {
  JoinCommunity,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import classes from './CommunityJoinButton.module.scss';

export function CommunityJoinButton({
  community,
}: {
  community: SingleCommunityType;
}) {
  const { t } = useTranslation(['globals']);
  const { data: authState } = useUserProfile();

  if (typeof authState === 'undefined') return null;
  const isGuest = !authState.isLoggedIn;
  const isMember = community.isMember;

  if (isGuest) {
    return (
      <div className={classes.button}>
        <Button
          variant={BUTTON_VARIANT.RED}
          size={BUTTON_SIZE.SMALL}
          onClick={() => openModal(MODAL.LOGIN, undefined, { login: true })}
        >
          {t('globals:signupToJoin')}
        </Button>
      </div>
    );
  }

  if (!isMember) {
    return (
      <div className={classes.button}>
        <JoinCommunity
          onSuccess={() => {
            toast.success(
              t('community:joinedCommunity.title', {
                community: community.name,
              }),
            );
          }}
        >
          {({ onJoin, isLoading }) => {
            return (
              <Button
                variant={BUTTON_VARIANT.BLUE}
                size={BUTTON_SIZE.SMALL}
                onClick={() => onJoin(community.communityId)}
                disabled={isLoading}
              >
                {t('community:joinCommunity')}
              </Button>
            );
          }}
        </JoinCommunity>
      </div>
    );
  }
  return <></>;
}
