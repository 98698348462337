import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

import shimmer from '@/styles/animations/shimmer.module.scss';

import classes from './Shimmer.module.scss';

export function Shimmer(props: SkeletonProps) {
  return (
    <Skeleton
      className={`${classes.shimmer}  ${shimmer['animate-shimmer']}`}
      {...props}
    />
  );
}
