import Skeleton from 'react-loading-skeleton';

export function CommunityHeadTitle({ name }: { name?: string }) {
  return (
    <div className="community-head-data flex column">
      <div className="flex middle">
        <h2 className="font-bold lexend-heading-h2">
          {name ? name : <Skeleton />}
        </h2>
      </div>
    </div>
  );
}
