import { Card } from '@/modules/shared/components/Atom/Card/Card';
import animateClasses from '@/styles/animations/shimmer.module.scss';

import classes from './InventoryItemsSkeleton.module.scss';

export function InventoryItemsSkeleton() {
  return (
    <div className={classes.inventory_items_loader}>
      <Card
        className={`${animateClasses['animate-shimmer']} ${classes.box}`}
        variant="outlined"
      ></Card>
      <Card
        variant="outlined"
        className={`${animateClasses['animate-shimmer']} ${classes.box}`}
      ></Card>
      <Card
        variant="outlined"
        className={`${animateClasses['animate-shimmer']} ${classes.box}`}
      ></Card>
    </div>
  );
}
