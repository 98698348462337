import {
  useCommunityDetail,
  useCommunityRanking,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Images } from '@/assets/imgs';
import { ResourceImage } from '@/modules/quests/components/ResourceImage/ResourceImage';
import { Card } from '@/modules/shared/components/Atom/Card/Card';

import { ResourceType } from '@xborglabs/ui-shared';
import classes from './CommunityRank.module.scss';

export function CommunityRank({
  communityId,
  resourceType,
  isError,
  isLoading,
}: {
  communityId: string;
  resourceType: ResourceType | undefined;
  isError: boolean;
  isLoading: boolean;
}) {
  const { t } = useTranslation(['globals']);
  const { data: authState } = useUserProfile();
  const { data: userRank } = useCommunityRanking(communityId);
  const { data: community } = useCommunityDetail(communityId);

  const user = authState?.profile;
  const balance = userRank?.balance ?? 0;
  const label = resourceType?.name;
  const text = `${balance} ${label}`;
  const currentRank = userRank?.leaderboardRank ?? 0;
  const percentage = useMemo(() => {
    const totalMembers = community?.memberCount;
    const currentRank = userRank?.leaderboardRank ?? 0;
    if (totalMembers && currentRank) {
      return Math.ceil(((currentRank - 1) / (totalMembers - 1)) * 100);
    }
    return 0;
  }, [community?.memberCount, userRank?.leaderboardRank]);
  const bgRankSrc = useMemo(() => {
    const currentRank = userRank?.leaderboardRank ?? 0;
    if (currentRank === 1) {
      return Images.leaderboard.gold;
    } else if (currentRank === 2) {
      return Images.leaderboard.silver;
    } else if (currentRank === 3) {
      return Images.leaderboard.bronze;
    }
    return Images.leaderboard.default;
  }, [userRank?.leaderboardRank]);

  if (!user || !userRank || !resourceType) return null;

  return (
    <Card variant="overlay" className={classes.rank_container}>
      <div className={classes.description}>
        <p className={classes.display_name}>{authState?.displayName ?? ''}</p>
        <div className={classes.resource_area}>
          <div className={classes.resource}>
            <ResourceImage
              isError={isError}
              isLoading={isLoading}
              resourceType={resourceType}
            />
          </div>
          <p className={classes.balance}>{text}</p>
        </div>
      </div>
      <div className="user-rank__content">
        <div className={classes.current_rank}>
          <Image
            src={bgRankSrc}
            alt="rank icon"
            fill
            className={classes.rank_bg}
          />
          <p className={classes.rank_label}>
            {currentRank ? `#${currentRank}` : t('na')}
          </p>
          <p className={classes.rank_percentage}>
            {t('community:topPercent', {
              percentage: percentage,
            })}
          </p>
        </div>
      </div>
    </Card>
  );
}
