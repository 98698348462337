import { SingleCommunityType } from '@xborglabs/ui-shared';
import { ReactNode } from 'react';

import { CommunityAvatarPreview } from '@/modules/community/components/CommunityAvatarPreview/CommunityAvatarPreview';

import { InventoryToggle } from '../InventoryToggle/InventoryToggle';
import classes from './CommunityInventory.module.scss';
import { InventoryItemsSkeleton } from './InventoryItemsSkeleton';

export type CommunityInventoryProps = {
  community: SingleCommunityType;
  slug?: string | string[];
  children: ReactNode;
  isLoading: boolean;
  hideOnMobile?: boolean;
};

export const CommunityInventoryContainer = ({
  community,
  slug,
  children,
  isLoading,
  hideOnMobile,
}: CommunityInventoryProps) => {
  if (Array.isArray(slug) || !slug) return null;

  return (
    <div className={classes.community_inventory}>
      <div className={classes.tabs_section}>
        <InventoryToggle slug={slug} />
      </div>
      {children}
      <CommunityAvatarPreview
        community={community}
        hideOnMobile={hideOnMobile}
      />
      {isLoading && <InventoryItemsSkeleton />}
    </div>
  );
};
