import { LayoutGroup, m } from 'framer-motion';
import Link from 'next/link';
import { useMemo, useRef } from 'react';

import classes from './Tabs.module.scss';

export type TabsType = {
  data: LinkSingleTab[];
  headClassName?: string;
};

export type LinkSingleTab = {
  label: string;
  slug: string;
  pathname: string;
  active?: boolean;
  hidden?: boolean;
};

export const LinkTabs = ({ data, headClassName }: TabsType) => {
  const filteredData = useMemo(
    () => data.filter((item) => !item.hidden),
    [data],
  );

  return (
    <LayoutGroup id="underline">
      <div className={[classes.tabs_container, headClassName].join(' ')}>
        <ul className={classes.tabs}>
          {filteredData.map((tab: LinkSingleTab) => (
            <TabItem key={tab.slug} tab={tab} />
          ))}
        </ul>
      </div>
    </LayoutGroup>
  );
};

export function TabItem({ tab }: { tab: LinkSingleTab }) {
  const ref = useRef<HTMLLIElement>(null);
  return (
    <m.li
      ref={ref}
      className={`${tab.active ? classes.active : classes.inactive} ${
        classes.tab
      }`}
      layout
    >
      <Link href={tab.pathname} className={classes.link_tab}>
        {tab.label}
        {tab.active && (
          <m.div layoutId="underline" className={classes.underline} />
        )}
      </Link>
    </m.li>
  );
}
