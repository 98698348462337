'use client';

import {
  JoinCommunity,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { toast } from '@/lib/toastify';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

export function JoinCommunitySection({ communityId }: { communityId: string }) {
  const { t } = useTranslation(['community']);
  const { data: authState } = useUserProfile();

  return (
    <div className="community-head-actions">
      <JoinCommunity
        onError={() => {
          toast.error(t('community:joinedCommunity.error'));
        }}
        onSuccess={(data) => {
          toast.success(
            t('community:joinedCommunity.title', { community: data.name }),
          );
        }}
      >
        {({ onJoin, isLoading }) => (
          <Button
            variant={BUTTON_VARIANT.RED}
            loading={isLoading}
            size={BUTTON_SIZE.LARGE}
            onClick={() =>
              authState?.isLoggedIn
                ? onJoin(communityId)
                : openModal(MODAL.LOGIN, undefined, { login: true })
            }
            style={{
              backgroundColor: 'var(--community-color, theme(brand, main))',
            }}
          >
            {t('community:joinCommunity')}
          </Button>
        )}
      </JoinCommunity>
    </div>
  );
}
