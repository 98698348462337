import { CommunityLeaderboard } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { match } from 'ts-pattern';

import { Leaderboard } from '@/modules/leaderboard';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import {
  ListError,
  leaderboardErrorImage,
} from '@/modules/shared/components/Template/Errors';

import { CommunityRank } from '../CommunityRank/CommunityRank';
import classes from './CommunityLeaderboard.module.scss';

export type CommunityLeaderboardProps = {
  communityId: string;
};

export const LeaderboardContainer = ({
  communityId,
}: CommunityLeaderboardProps) => {
  const limit = 25;

  const { t } = useTranslation(['errors']);

  return (
    <div className={classes.leaderboard_page}>
      <CommunityLeaderboard communityId={communityId} limit={limit}>
        {({
          data,
          isError,
          isLoading,
          refetch,
          resourceType,
          setPage,
          status,
        }) => {
          return match([status, data?.users?.length ?? 0])
            .with(['success', 0], () => (
              <ListError
                text={t('errors:globalErrors.leaderboard_list_empty')}
                image={leaderboardErrorImage}
                hasVideo
              />
            ))
            .otherwise(() => (
              <>
                <div className={classes.rank_container}>
                  <CommunityRank
                    communityId={communityId}
                    resourceType={resourceType}
                    isError={isError}
                    isLoading={isLoading}
                  />
                </div>
                <Card
                  className={classes.leaderboard_container}
                  variant="overlay"
                >
                  <Leaderboard
                    data={data?.users}
                    isError={isError}
                    isLoading={isLoading}
                    refetch={refetch}
                    resourceType={resourceType}
                  />
                  {data ? (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={({ selected }) => {
                        setPage(selected + 1);
                      }}
                      pageRangeDisplayed={5}
                      pageCount={data.total / limit}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      className="page-pagination lexend-body-xs2"
                    />
                  ) : null}
                </Card>
              </>
            ));
        }}
      </CommunityLeaderboard>
    </div>
  );
};
