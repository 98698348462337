import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';

import { Images } from '@/assets/imgs';

export function CommunityHeadLogo({
  isLoading,
  logoImageUrl,
}: {
  isLoading: boolean;
  logoImageUrl?: string;
}) {
  return (
    <div className="community-head-logo">
      {!isLoading ? (
        <Image
          src={logoImageUrl ?? Images.communityview.logo}
          width={96}
          height={96}
          alt=""
          className="community-logo"
        />
      ) : (
        <Skeleton />
      )}
    </div>
  );
}
