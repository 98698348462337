import { CSSProperties } from 'react';

import classes from './Progressbar.module.scss';
export type ProgressbarType = {
  progress: number;
  className?: string;
  color?: 'brand' | 'community' | 'success' | 'community_gradient';
  variant?: 'dashed' | 'solid';
  size?: 'extra_small' | 'small' | 'large';
  label?: JSX.Element;
};

export const Progressbar = ({
  progress,
  className,
  color = 'brand',
  variant = 'dashed',
  size = 'large',
  label,
}: ProgressbarType) => {
  const percentage = progress + '%';
  return (
    <div
      className={`${classes.progressbar} ${classes[size]} ${
        classes[`variant_${variant}`]
      } ${className ?? ''} ${color ? classes[color] : ''}`}
    >
      <div
        className={`${classes.bar} ${
          variant === 'solid' ? classes.no_border : ''
        } `}
        style={
          {
            ['--progress']: percentage,
          } as CSSProperties
        }
      ></div>
      <div
        className={`${classes[size]} ${
          variant === 'solid' ? classes.label_hidden : classes.label
        }`}
      >
        {label ? label : percentage}
      </div>
    </div>
  );
};
