import { useInventorySlots } from '@xborglabs/ui-shared/dist/client';
import { SingleCommunityType } from '@xborglabs/ui-shared/lib/features/community/types';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';

import { Images } from '@/assets/imgs';
import { useAppAvatar } from '@/modules/community/queries';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import ImageCanvas from '@/modules/shared/components/Atom/ImageCanvas/ImageCanvas';
import { InventoryActions } from '@/redux/inventory/actions';
import {
  selectIsAvatarChanging,
  selectIsAvatarLoading,
} from '@/redux/inventory/selector';
import { GLOBAL_ROUTES } from '@/routes';
import { useAppDispatch } from '@/store';

import classes from './CommunityAvatarPreview.module.scss';

export type CommunityAvatarPreviewProps = {
  community: SingleCommunityType;
  hideOnMobile?: boolean;
};

export function CommunityAvatarPreview({
  community,
  hideOnMobile,
}: CommunityAvatarPreviewProps) {
  const { t } = useTranslation(['community']);
  const {
    data: avatar,
    isRefetching,
    isLoading,
  } = useAppAvatar({
    communityId: community.communityId,
    slug: community.slug,
    enabled: community.hasAvatar,
  });
  const isAvatarChanging = useSelector(selectIsAvatarChanging(community.slug));
  const dispatch = useAppDispatch();
  const isAvatarLoading = useSelector(selectIsAvatarLoading(community.slug));
  const shouldShowLoadingScreen =
    isAvatarLoading || isAvatarChanging || (isLoading && !isRefetching);
  // Classnames
  const state = 'not-joined';
  const avatarLoaded = shouldShowLoadingScreen ? 'loading' : 'loaded';
  const className = `${
    classes.avatar_preview_container
  } ${state} ${avatarLoaded} ${hideOnMobile ? classes.hide_on_mobile : ''}`;

  return (
    <div className={className}>
      <div className={classes['avatar-image-wrapper']}>
        <div className={classes['images-container']}>
          {shouldShowLoadingScreen && (
            <Image
              src={Images.inventory.skeleton}
              alt="skeleton loader"
              className={classes['avatar-loading']}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
          <Image
            src={Images.communityview.platform}
            alt="Avatar podium"
            className={classes['platform-image']}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
          {avatar?.mediaUrl && !isAvatarChanging && (
            <ImageCanvas
              className={classes['avatar-image']}
              imageUrl={avatar.mediaUrl}
              width={2000}
              height={3000}
              onLoad={() => {
                dispatch(
                  InventoryActions.setAvatarLoading({
                    communityKey: community.slug,
                    isLoading: false,
                  }),
                );
              }}
              onLoadStart={() => {
                dispatch(
                  InventoryActions.setAvatarLoading({
                    communityKey: community.slug,
                    isLoading: true,
                  }),
                );
              }}
            />
          )}
        </div>
        <div className={classes.avatar_options}>
          {avatar?.mediaUrl && !isAvatarChanging && (
            <Link
              className={`${classes.inventory_slot} ${classes.download_avatar_icon}`}
              target="_blank"
              title={t('community:downloadAvatar')!}
              href={avatar?.mediaUrl}
            >
              <Icon.download size={32} color={'white'} />
            </Link>
          )}
          <InventorySlotButtons community={community} />
        </div>
      </div>
    </div>
  );
}

function InventorySlotButtons({
  community,
}: {
  community: SingleCommunityType;
}) {
  const { data } = useInventorySlots(community.communityId);
  return data?.slots ? (
    <>
      {data.slots.map((slot) => (
        <Link
          href={GLOBAL_ROUTES.COMMUNITY_INVENTORY_ALL_ITEMS(
            community.slug,
            slot.slug,
          )}
          key={slot.slotId}
          className={classes.inventory_slot}
        >
          {slot.slug}
        </Link>
      ))}
    </>
  ) : null;
}
