'use client';

import { SingleCommunityType } from '@xborglabs/ui-shared';
import Image from 'next/image';

import { Images } from '@/assets/imgs';
import { Shimmer } from '@/modules/shared/components/Template/Loader/Shimmer';

import { CommunityHeadLogo } from './CommunityHeadLogo';
import { CommunityHeadMembership } from './CommunityHeadMembership';
import { CommunityHeadTitle } from './CommunityHeadTitle';

export type CommunityHeaderProps = {
  community?: SingleCommunityType;
  visibleClass: string;
  setVisibility: (v: boolean) => void;
  visible: boolean;
};

export const CommunityHeader = ({
  community,
  visibleClass,
}: CommunityHeaderProps) => {
  if (!community)
    return (
      <div className="community-head-container w-full">
        <Shimmer />
      </div>
    );

  return (
    <div className="community-head-container w-full">
      <div className={`community-head flex bottom ${visibleClass}`}>
        <div className={`community-head-details flex column `}>
          <div className="flex between bottom w-full">
            <div className="flex flex-wrap middle gap-small">
              <CommunityHeadLogo
                isLoading={!community}
                logoImageUrl={community?.content?.images?.logoImageUrl}
              />

              <CommunityHeadTitle name={community?.name} />
            </div>
            <CommunityHeadMembership
              isMember={community?.isMember}
              communityId={community?.communityId}
            />
          </div>
        </div>
      </div>
      <Image
        src={community?.content?.images?.logoImageUrl ?? Images.logo}
        priority
        fill
        alt={community?.name + ' banner'}
        sizes="(100vw, 100vh)"
        className="community-head-background"
      />
    </div>
  );
};
