import { JoinCommunitySection } from './JoinCommunitySection';

export function CommunityHeadMembership({
  communityId,
  isMember,
}: {
  communityId?: string;
  isMember?: boolean;
}) {
  if (!communityId) return null;
  return !isMember ? <JoinCommunitySection communityId={communityId} /> : null;
}
