import React, { useEffect, useMemo, useRef } from 'react';

type ImageCanvasProps = {
  imageUrl: string;
  width: number;
  height: number;
  onLoad?: () => void;
  onLoadStart?: () => void;
  className?: string;
};

function ImageCanvas({
  imageUrl,
  width,
  height,
  onLoad,
  onLoadStart,
  className,
}: ImageCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const url = useMemo(() => imageUrl, [imageUrl]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (!canvas || !context) {
      console.error('Canvas context not available');
      return;
    }

    // Create an image element
    const img = new Image();

    // Set the source of the image to your URL
    img.src = url;
    onLoadStart?.();

    // When the image is loaded, draw it on the canvas
    img.onload = function () {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      onLoad?.();
    };

    // If there's an error loading the image, handle it
    img.onerror = function (error) {
      console.error('Error loading image:', error);
    };
  }, [url]);

  return (
    <canvas
      className={className}
      ref={canvasRef}
      width={width}
      height={height}
      about="avatar"
    />
  );
}

export default ImageCanvas;
